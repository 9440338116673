import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.notifications, { type, payload }) {
    switch (type) {
        case types.PATIENT_USER_NOTIFICATION_REQUEST:
            return {
                ...initialState.notifications,
                isLoading: true,
                isStatus: 'request',
                request: payload,
                successResponse: {},
                errorResponse: {}
            };
        case types.PATIENT_USER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isStatus: 'success',
                successResponse: payload,
                request: {}
            };
        case types.PATIENT_USER_NOTIFICATION_FAILED:
            return {
                ...state,
                isLoading: false,
                isStatus: 'error',
                successResponse: {},
                errorResponse: payload,
                request: {}
            };
        default:
            return state;
    }
}