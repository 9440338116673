import React from 'react';

class SelectPage extends React.Component {
    redirectHandler = (e) => {
        this.props.history.push('/patients/' + this.props.patientId + '/' + e.target.value)
    }
    render() {
        return (
            <div className="select-dropdown" >
                <select className="icon arrow-down-sign-to-na" onChange={this.redirectHandler} >
                    <option defaultValue=''>More Details</option>
                    <option value="prescriptions">Prescriptions</option>
                    <option value="prescription-permissions" >Prescription Permission</option>
                    <option value="certificates">Certificates</option>
                    <option value="immunizations">Immunization</option>
                </select>
            </div>
        )
    }

}
export default SelectPage