import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as authAction from '../../actions/authAction';
import { Helmet } from 'react-helmet';
import Loader from '../../components/Loader';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contact_number: '',
            password: '',
            hidden: true,
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.login(this.state.contact_number, this.state.password, this.props.history);
    }

    render() {
        const { authenticated } = this.props;
        return (
            <section class="middle-wrapper">
                <div className="container flex v-center">
                    <Helmet>
                        <title>Login</title>
                    </Helmet>
                    {authenticated && <Redirect to="/dashboard" />}
                    <div className="form-div">
                        <h2>Welcome to daisy</h2>
                        <p>If this is your first visit to the daisy, Login with your User ID as normal. You can then confirm an email address for future sign-ins.</p>
                        <div>
                            <Loader />
                            <form onSubmit={this.handleSubmit} className="form">
                                <div className="form-field">
                                    <input type="number" className="form-control" placeholder="Mobile" id="contact_number" name="contact_number" value={this.state.contact_number} onChange={this.handleChange} autoComplete="contact_number" />
                                    {this.validator.message('Mobile', this.state.contact_number, 'required')}
                                </div>
                                <div className="form-field" >
                                    <input type={this.state.hidden ? "password" : "text"} className="form-control" placeholder="Password" id="password" name="password" value={this.state.password} onChange={this.handleChange} autoComplete="new-password" />
                                    {this.validator.message('password', this.state.password, 'required')}
                                    <i className={this.state.hidden ? "pass-show" : "pass-hide"} onClick={this.toggleShow}></i>
                                </div>
                                <div className="form-field text-right">
                                    <Link to="/forgot-password" className="forgot-password d-blcok pb-20">Forgot Password?</Link>
                                </div>
                                <div className="form-field">
                                    <button type="submit" className="btn">Login</button>

                                </div>
                                <p className="text-center">Don't have an account ? <Link to="/registration" className="link-color">Sign up now</Link></p>
                            </form>
                        </div>
                    </div>
                    <div className="banner">
                        <img src="images/banner.png" alt="banner" />
                    </div>
                </div>
            </section>);
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.session.authenticated
});

const mapActionsToProps = ({
    login: authAction.login
})

export default connect(mapStateToProps, mapActionsToProps)(LoginPage);