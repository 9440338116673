export const allowUserRole = [
];

export const parish = {
    'St. Andrew': 'St. Andrew',
    'Kingston': 'Kingston',
    'St. Thomas': 'St. Thomas',
    'Portland': 'Portland',
    'St. Mary': 'St. Mary',
    'St. Ann': 'St. Ann',
    'Trelawny': 'Trelawny',
    'St. James': 'St. James',
    'Hanover': 'Hanover',
    'Westmoreland': 'Westmoreland',
    'St. Elizabeth': 'St. Elizabeth',
    'Manchester': 'Manchester',
    'Clarendon': 'Clarendon',
    'St. Catherine': 'St. Catherine',
};

export const DIMENSION = 'small';

export const INSURANCE = {
    "private-insurance-sagicore": 'Private Insurance Sagicor',
    "private-insurance-medicus": 'Private Insurance Medicus',
    "private-insurance-canopy": 'Private Insurance Canopy',
    'jadep': 'JADEP',
    'nhf': 'NHF',
    'goj': 'GOJ',
    'other': 'Other',
    'no': '--'
}
