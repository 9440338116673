import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import PrescriptionTable from './report-tables/prescription';
import PatientService from '../../services/authService';
import Notification from '../../utils/notification';
import * as authAction from '../../actions/authAction';
import { connect } from 'react-redux';
import SelectPage from '../../components/SelectPage';

class PrescriptionPermission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: this.props.match.params.patientId,
            prescription_ids: ''
        }
    }

    componentDidMount() {
        if (this.props.patient.patientId !== this.state.patientId) {
            this.props.getPatientById(this.state.patientId);
        }
        document.body.classList.add('body-bg-gray');
    }

    submitHandler = () => {
        // console.log(this.refs); return 
        if (_.isEmpty(this.state.prescription_ids)) {
            Notification.show('error', { 'message': 'Select prescription' });
            return false
        }

        if (_.isEmpty(this.refs.permission_status.value)) {
            Notification.show('error', { 'message': 'Select permission status' });
            return false
        }
        if (this.refs.permission_status && this.refs.permission_status.value) {
            let value = this.refs.permission_status.value;
            const postData = {
                prescription_ids: this.state.prescription_ids,
                status: value
            }
            PatientService.permissionStatus(this.state.patientId, postData).then(resp => {
                Notification.show('success', resp.data);
                window.location.reload();
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
    }

    getCheckedPrescription = (prescription_ids) => {
        this.setState({
            prescription_ids: prescription_ids
        })
    }

    render() {
        let patient_detail = this.props.patient.successResponse;
        const { patientId } = this.state;
        return (<React.Fragment>
            <Helmet>
                <title>Prescription</title>
            </Helmet>
            <section className="middle-wrapper">
                <div className="container">
                    <div className="list-header">
                        <Link to={"/patients/" + patientId + "/prescriptions"} className="white-bg"><span>&#8249;</span> Back</Link>
                        <span className="patient-name">{patient_detail.full_name}</span>
                        <SelectPage history={this.props.history} patientId={patientId} />
                    </div>
                    <section>
                        <div className="container prescription-tabs">
                            <ul className="tabs">
                                <li className="tab-link active" data-tab="tab-1">Prescription Permission</li>
                            </ul>
                            <div id="tab-1" className="tab-content active">
                                <div className="head-selection">
                                    <div className="select-dropdown" >
                                        <select className="icon arrow-down-sign-to-na" ref='permission_status'>
                                            <option default value=''>--Select--</option>
                                            <option value="allow" >Allow</option>
                                            <option value="revoke">Revoke</option>
                                        </select>
                                    </div>
                                    <button type="submit" className="sbm-btn btn" onClick={this.submitHandler}>Submit</button>
                                </div>
                                <div className="">
                                    <PrescriptionTable
                                        patientId={patientId}
                                        hideCheckbox={false}
                                        hideActions={true}
                                        handleCheckedPrescription={this.getCheckedPrescription} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </React.Fragment>);
    }
}
const mapStateToProps = (state) => ({
    patient: state.patient
});
const mapActionsToProps = {
    getPatientById: authAction.getPatientById
};
export default connect(mapStateToProps, mapActionsToProps)(PrescriptionPermission)
