export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_WARNING = 'ALERT_WARNING';

export const SHOW_PAGE_LOADER = 'SHOW_PAGE_LOADER';
export const HIDE_PAGE_LOADER = 'HIDE_PAGE_LOADER';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const PATIENT_LIST_REQUEST = 'PATIENT_LIST_REQUEST';
export const PATIENT_LIST_SUCCESS = 'PATIENT_LIST_SUCCESS';
export const PATIENT_LIST_FAILED = 'PATIENT_LIST_FAILED';
export const PATIENT_LIST_RESET = 'PATIENT_LIST_RESET';

export const PHARMACY_LIST_REQUEST = 'PHARMACY_LIST_REQUEST';
export const PHARMACY_LIST_SUCCESS = 'PHARMACY_LIST_SUCCESS';
export const PHARMACY_LIST_FAILED = 'PHARMACY_LIST_FAILED';

export const MEDICINE_LIST_REQUEST = 'MEDICINE_LIST_REQUEST';
export const MEDICINE_LIST_SUCCESS = 'MEDICINE_LIST_SUCCESS';
export const MEDICINE_LIST_FAILED = 'MEDICINE_LIST_FAILED';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAILURE = 'NEW_PASSWORD_FAILURE';

export const ADD_PATIENT = 'ADD_PATIENT';
export const FETCH_PATIENT = 'FETCH_PATIENT';
export const RESET_PATIENT = 'RESET_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';

export const VERIFY_PERSCRIPTION = 'VERIFY_PERSCRIPTION';
export const DOCTOR_SELECT = 'DOCTOR_SELECT';

export const RESET_PERSCRIPTION = 'RESET_PERSCRIPTION';
export const FETCH_PERSCRIPTION = 'FETCH_PERSCRIPTION';
export const LIST_PERSCRIPTION = 'LIST_PERSCRIPTION';
export const FILTER_PERSCRIPTION = 'FILTER_PERSCRIPTION';

export const RESET_PERSCRIPTION_ORDER = 'RESET_PERSCRIPTION_ORDER';
export const VERIFY_PERSCRIPTION_ORDER = 'VERIFY_PERSCRIPTION_ORDER';
export const FILTER_PERSCRIPTION_ORDER = 'FILTER_PERSCRIPTION_ORDER';
export const LIST_PERSCRIPTION_ORDER = 'LIST_PERSCRIPTION_ORDER';
export const FETCH_PERSCRIPTION_ORDER = 'FETCH_PERSCRIPTION_ORDER';

export const FETCH_PATIENT_WEIGHT_DATA = 'FETCH_PATIENT_WEIGHT_DATA';
export const FETCH_PATIENT_BLOOD_PRESSURE_DATA = 'FETCH_PATIENT_BLOOD_PRESSURE_DATA';

export const SEARCH_PATIENT = 'SEARCH_PATIENT';

export const REQUEST_COMMISSIONS = "REQUEST_COMMISSIONS";
export const LIST_COMMISSIONS = "LIST_COMMISSIONS";


export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const PATIENTS_REQUEST = 'PATIENTS_REQUEST';
export const PATIENTS_SUCCESS = 'PATIENTS_SUCCESS';
export const PATIENTS_FAILURE = 'PATIENTS_FAILURE';

export const PATIENT_REQUEST = 'PATIENT_REQUEST';
export const PATIENT_SUCCESS = 'PATIENT_SUCCESS';
export const PATIENT_FAILURE = 'PATIENT_FAILURE';

export const PRISCRIPTIONLIST_REQUEST = 'PRISCRIPTIONLIST_REQUEST';
export const PRISCRIPTIONLIST_SUCCESS = 'PRISCRIPTIONLIST_SUCCESS';
export const PRISCRIPTIONLIST_FAILURE = 'PRISCRIPTIONLIST_FAILURE';

export const PATHOLOGYLIST_REQUEST = 'PATHOLOGYLIST_REQUEST';
export const PATHOLOGYLIST_SUCCESS = 'PATHOLOGYLIST_SUCCESS';
export const PATHOLOGYLIST_FAILURE = 'PATHOLOGYLIST_FAILURE';

export const RADIOLOGYLIST_REQUEST = 'RADIOLOGYLIST_REQUEST';
export const RADIOLOGYLIST_SUCCESS = 'RADIOLOGYLIST_SUCCESS';
export const RADIOLOGYLIST_FAILURE = 'RADIOLOGYLIST_FAILURE';

export const PRESCRIPTIONDETAIL_REQUEST = 'PRESCRIPTIONDETAIL_REQUEST';
export const PRESCRIPTIONDETAIL_SUCCESS = 'PRESCRIPTIONDETAIL_SUCCESS';
export const PRESCRIPTIONDETAIL_FAILURE = 'PRESCRIPTIONDETAIL_FAILURE';


export const ADD_NEW_PATIENT = 'ADD_NEW_PATIENT';
export const ADD_NEW_PATIENT_SUCCESS = 'ADD_NEW_PATIENT_SUCCESS';
export const ADD_NEW_PATIENT_FAILURE = 'ADD_NEW_PATIENT_FAILURE';


export const PATIENT_OTP = 'PATIENT_OTP';
export const PATIENT_OTP_SUCCESS = 'PATIENT_OTP_SUCCESS';
export const PATIENT_OTP_FAILURE = 'PATIENT_OTP_FAILURE';

export const PATIENT_RESEND_OTP = 'PATIENT_RESEND_OTP';
export const PATIENT_RESEND_OTP_SUCCESS = 'PATIENT_RESEND_OTP_SUCCESS';
export const PATIENT_RESEND_OTP_FAILURE = 'PATIENT_RESEND_OTP_FAILURE';


export const EDITPROFILE_REQUEST = 'EDITPROFILE_REQUEST';
export const EDITPROFILE_SUCCESS = 'EDITPROFILE_SUCCESS';
export const EDITPROFILE_FAILURE = 'EDITPROFILE_FAILURE';

export const PATIENT_CERTIFICATE_LIST_REQUEST = 'PATIENT_CERTIFICATE_LIST_REQUEST';
export const PATIENT_CERTIFICATE_LIST_SUCCESS = 'PATIENT_CERTIFICATE_LIST_SUCCESS';
export const PATIENT_CERTIFICATE_LIST_FAILED = 'PATIENT_CERTIFICATE_LIST_FAILED';

export const PATIENT_USER_NOTIFICATION_SUCCESS = 'PATIENT_USER_NOTIFICATION_SUCCESS';
export const PATIENT_USER_NOTIFICATION_FAILED = 'PATIENT_USER_NOTIFICATION_FAILED';
export const PATIENT_USER_NOTIFICATION_REQUEST = 'PATIENT_USER_NOTIFICATION_REQUEST';

export const OTHER_PRESCRIPTION_LIST_SUCCESS = 'OTHER_PRESCRIPTION_LIST_SUCCESS';
export const OTHER_PRESCRIPTION_LIST_FAILED = 'OTHER_PRESCRIPTION_LIST_FAILED';
export const OTHER_PRESCRIPTION_LIST_REQUEST = 'OTHER_PRESCRIPTION_LIST_REQUEST';
