import React from 'react';

const ViewVital = ({ vital }) => {
    return <div className="content-wrapper">
        <h6>Vitals</h6>
        <ul className="details-listing">
            <li>Height: <span>{vital.patient_height ? parseFloat(vital.patient_height) : '--'} cms</span></li>
            <li>Weight: <span>{vital.patient_weight ? parseFloat(vital.patient_weight) : '--'} kgs</span></li>
            <li>Blood Pressure: <span>{vital.patient_blood_pressure_max ? parseFloat(vital.patient_blood_pressure_max) : '--'}/{vital.patient_blood_pressure_min ? parseFloat(vital.patient_blood_pressure_min) : '--'} mm of Hg</span></li>
            <li>Pulse Rate: <span>{vital.patient_pulse_rate ? parseFloat(vital.patient_pulse_rate) : '--'} BPM</span></li>
        </ul>
        <ul className="details-listing">
            <li>Pulse Ox: <span>{vital.patient_pulse_ox ? parseFloat(vital.patient_pulse_ox) : '--'} SpO2</span></li>
            <li>BMI: <span>{vital.patient_bmi ? parseFloat(vital.patient_bmi) : '--'} kg/m²</span></li>
            <li>Temperature: <span>{vital.patient_temperature ? parseFloat(vital.patient_temperature) : '--'} F</span></li>
            <li>RBG: <span>{vital.patient_rbg ? parseFloat(vital.patient_rbg) : '--'}</span></li>
        </ul>
        <ul className="details-listing">
            <li>Blood Group: <span>{vital.patient_blood_group ? vital.patient_blood_group : '--'}</span></li>
            <li>Allergy: <span>{vital.patient_allergy ? vital.patient_allergy.join(", ") : '--'}</span></li>
            <li>Urinalysis: <span>{vital.patient_urinalysis ? vital.patient_urinalysis : '--'}</span></li>
        </ul>
    </div>
}
export default ViewVital