import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as AuthAction from '../../actions/authAction';
import Loader from '../../components/Loader';

class ForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contact_number: '',
        };

        this.validator = new SimpleReactValidator({ locale: 'en' });
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.forgotPassword(this.state.contact_number, this.props.history);
    }

    render() {
        return (
            <div className="middle-wrapper">
                <div className="container flex v-center">
                    <div className="form-div">
                        <h2>Forgot Password</h2>
                        <p>Enter your Registered Mobile number to reset your password</p>
                        <Loader />
                        <div>
                            <form action="" method="" className="form" onSubmit={this.handleSubmit}>
                                <div className="form-field">
                                    <input type="text" placeholder="Mobile Number" id="contact_number" name="contact_number" value={this.state.contact_number} onChange={this.handleChange} autoComplete="contact_number" />
                                    {this.validator.message('contact_number', this.state.contact_number, 'required')}
                                </div>
                                <div className="form-field">
                                    <button type="submit" className="btn btn-primary mb-2">Submit</button>
                                    <a href="/login" className="btn d-block no-bg"> Cancel </a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="banner">
                        <img src="images/banner.png" alt="banner" />
                    </div> 
                </div>
            </div>
            
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

const mapActionsToProps = ({
    forgotPassword: AuthAction.forgotPassword
})

export default connect(mapStateToProps, mapActionsToProps)(ForgotPasswordPage);