import _ from 'lodash';

export const isAuthenticated = user => !!user;

export const hasPermission = (user, rights) => {
    if (_.isEmpty(user)) {
        return false;
    }

    return rights.some(right => user.rights.includes(right));
}

export const hasRole = (user, roles) => {
    if (_.isEmpty(user)) {
        return false;
    }

    return roles.some(role => user.roles.includes(role));
}