import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Header from '../header/Header';
import Content from '../components/Content';
import { NotificationContainer } from 'react-notifications';
import { hasRole } from '../utils/auth';

class PrivateLayout extends React.Component {
    constructor(props) {
        super(props);

        document.body.classList.remove("login-forgot-background");
    }
    render() {
        const { component: Component, authenticated, allowRoles, user, ...rest } = this.props;

        return (
            <Route {...rest} render={props => (
                authenticated ? (
                    <React.Fragment>
                        <Header {...props} />
                        <Content>
                            <NotificationContainer />
                            {
                                allowRoles ? (hasRole(user, allowRoles) ? <Component {...props} /> : '') : <Component {...props} />
                            }
                        </Content>
                        <footer>
                          <div className="container">
                                <p>Copyright © 2020 daisy. All rights reserved. <a href="/privacy-policy.html" target="_blank">Privacy Policy</a> and <a href="/terms-conditions.html" target="_blank">Terms & Conditions </a></p>
                          </div>
                        </footer>
                    </React.Fragment>
                ) : (<Redirect to="/" />)
            )} />
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.session.authenticated,
    user: state.session.user
});

export default connect(mapStateToProps, null)(PrivateLayout);