import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import SimpleReactValidator from 'simple-react-validator';
import * as authAction from '../../actions/authAction';

class registrationOtp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            otp: '',
            time: {},
            seconds: 59
        };
        this.contact_no = localStorage.getItem('contact_no');

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resendOtp = this.resendOtp.bind(this);
    }


    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.registrationVerify(this.state.otp, this.contact_no, this.props.history);
    }

    resendOtp(e) {
        e.preventDefault();
        this.props.registrationOtpResend(this.contact_no)
    }


    render() {
        return (<React.Fragment>
            <Helmet>
                <title>Verify</title>
            </Helmet>
            <section className="middle-wrapper v-center">
                <div className="container">
                    <div className="add-patient">
                        <h4>Verify OTP</h4>
                        <i className="patient-code-icon"></i>
                        <p className="msg">Please Enter OTP you recieved on your registered mobile number</p>
                        <form action="" method="" className="form verification-code" onSubmit={this.handleSubmit}>
                            <div className="form-field w-100">
                                <input type="text" name="otp" placeholder="Enter OTP" id="otp" value={this.state.otp} onChange={this.handleChange} />
                                {this.validator.message('Code', this.state.otp, 'required')}
                            </div>
                            <div className="form-field submit-btn">
                                <input type="submit" value="Verify" className="btn" />
                            </div>
                        </form>
                        <p className="text-center">Didn't get the code ? <Link to="#" className="link-color" onClick={this.resendOtp}>Resend code</Link></p>
                    </div>
                </div>
            </section>
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
});

const mapActionsToProps = ({
    registration: authAction.registration,
    registrationOtpResend: authAction.registrationOtpResend,
    registrationVerify: authAction.registrationVerify,

})

export default connect(mapStateToProps, mapActionsToProps)(registrationOtp);