import React from 'react';
import { Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import Header from '../header/Header';

class PublicLayout extends React.Component {
    constructor(props) {
        super(props);

        document.body.classList.add("login-forgot-background");
    }
    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route {...rest} render={props => (
                <React.Fragment>
                    <Header {...props} />
                    <NotificationContainer />
                    <Component {...props} />
                    <footer>
                          <div className="container">
                            <p>Copyright © 2020 daisy. All rights reserved. <a href="/privacy-policy.html" target="_blank">Privacy Policy</a> and <a href="terms-conditions.html" target="_blank">Terms & Conditions </a></p>
                          </div>
                        </footer>
                </React.Fragment>
            )} />
        );
    }
}

export default PublicLayout;