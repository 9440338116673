import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from "react-router-dom";
import * as AuthAction from '../actions/authAction';
import _ from 'lodash';
import { getDateFormat } from '../utils/helpers'

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.onLogout = this.onLogout.bind(this);
    }

    fetchNotifications = () => {
        this.props.getNotifications();
    }

    onLogout() {
        this.props.logout();
    }

    showNotifications = () => {
        const { notifications: { successResponse } } = this.props;
        return successResponse.data.map((item) => (
            item.notification_type == 'permission_request' && <li key={item.id}>
                <h6>Patient: <span>{item.payload.patient_name}</span></h6>
                <p>{item.payload.requested_by_doctor.name}, {item.payload.requested_by_doctor.specialization} has asked permission for prescription: {item.payload.requested_to_doctor.name}, {item.payload.requested_to_doctor.specialization}</p>
                <span className="date">Created on - {getDateFormat(item.created_at)}</span>
            </li>
        ))
    }
    render() {
        const { user } = this.props;
        const { notifications: { successResponse } } = this.props;
        return (
            <header>
                <div className="container flex">
                    <NavLink to="/dashboard"><img src="/images/daisy-logo.svg" alt="daisy logo" /></NavLink>
                    <nav>
                        <ul className="flex menu">
                            <li className=""><NavLink to="/dashboard">Home</NavLink></li>
                            <li className=""><NavLink to="/contact-us">Contact us</NavLink></li>
                            <li className=""><NavLink to="/faqs">Faq</NavLink></li>
                            {!_.isEmpty(user) && <li className="notification_menu" onClick={this.fetchNotifications}><a href="#"><i className="notification_icon"></i></a>
                                {successResponse && !_.isEmpty(successResponse.data) && <div className="notification_details">
                                    <ul>
                                        {this.showNotifications()}
                                    </ul>
                                    {/* <a className="see-all-notification" href="#">See All Notification</a> */}
                                </div>}
                            </li>}
                            {!_.isEmpty(user) && <li className="setting"><a href="#"><i className="settings_icon"></i></a>
                                <ul className="sub-menu">
                                    <li className="menu-title">Settings</li>
                                    <li><NavLink to="/edit" className="flex"><i className="avatar-icon"></i><p><span>{user.full_name}</span><small>{user.contact_number}</small></p></NavLink></li>
                                    <li><NavLink to="/change-password"
                                        className="flex"><i className="password-icon"></i><p><span>Change Password</span></p></NavLink></li>
                                    <li><Link onClick={this.onLogout} className="flex"><i className="logout-icon"></i><p><span>Logout</span></p></Link></li>
                                </ul>
                            </li>}
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    notifications: state.notifications
});

const mapActionsToProps = ({
    logout: AuthAction.logout,
    getNotifications: AuthAction.getPatientUserNotification,
})

export default connect(mapStateToProps, mapActionsToProps)(Header);
