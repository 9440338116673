import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import * as AuthAction from '../../actions/authAction';
import queryString from 'query-string';
import Loader from '../../components/Loader';

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = queryString.parse(this.props.location.search);

        this.state = {
            contact_number: queryParams.contact_number,
            otp: '',
            password: '',
            password_confirmation: ''
        };
        this.contact_no = localStorage.getItem('contact_no');
        this.validator = new SimpleReactValidator({ locale: 'en' });
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resendOtp = this.resendOtp.bind(this);

    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.resetPassword(this.state.contact_number, this.state.otp, this.state.password, this.state.password_confirmation, this.props.history);

        this.validator.hideMessages();
    }
    componentWillUnmount() {
        this.setState({
            contact_number: '',
            otp: '',
            password: '',
            password_confirmation: ''
        });
    }
    resendOtp(e) {
        e.preventDefault();
        this.props.forgotPasswordResend(this.contact_no)
    }

    render() {
        return (
            <div className="middle-wrapper">
                <div className="container flex v-center">
                    <div className="form-div">
                        <h2>Reset Your Password</h2>
                        <Loader />
                        <div>
                            <form action="" method="" className="form" onSubmit={this.handleSubmit}>
                                <div className="form-field">
                                    <input type="text" name="contact_number" id="contact_number" value={this.state.contact_number} disabled className="form-control" />
                                </div>
                                <div className="form-field">
                                    <input type="text" name="otp" placeholder="Enter OTP" id="otp" value={this.state.otp} onChange={this.handleChange} className="form-control" />
                                    {this.validator.message('Code', this.state.otp, 'required')}
                                </div>
                                <div className="form-field">
                                    <input type="password" className="form-control" placeholder="Password" id="password" name="password" value={this.state.password} onChange={this.handleChange} autoComplete="new-password" />
                                    {this.validator.message('password', this.state.password, 'required')}
                                </div>
                                <div className="form-field">
                                    <input type="password" className="form-control" placeholder="Confirm Password" id="password_confirmation" name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} autoComplete="new-password" />
                                    {this.validator.message('password_confirmation', this.state.password_confirmation, 'required')}
                                </div>
                                <div className="form-group clearfix">
                                    <button type="submit" className="btn btn-primary float-left">Reset Password</button>
                                </div>
                            </form>
                            <p className="text-center">Didn't get the code ? <Link to="#" className="link-color" onClick={this.resendOtp}>Resend code</Link></p>
                        </div>
                    </div>
                    <div className="banner">
                        <img src="images/banner.png" alt="banner" />
                    </div> 
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

const mapActionsToProps = ({
    resetPassword: AuthAction.resetPassword,
    forgotPasswordResend: AuthAction.forgotPasswordResend

})

export default connect(mapStateToProps, mapActionsToProps)(ResetPasswordPage);