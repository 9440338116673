import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from "react-router-dom";
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import LoginPage from './pages/auth/LoginPage';
import RegistrationPage from './pages/auth/RegistrationPage';
import RegistrationOtp from './pages/otp/registrationOtp';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ChangePasswordPage from "./pages/auth/ChangePasswordPage";
import editProfile from "./pages/auth/editProfile";
import DashboardPage from './pages/dashboard/DashboardPage';
import PatientCode from './pages/patient-code/AddPatientCode';
import PatientOtp from './pages/otp/patientOtp';
import ProfileDetail from './pages/profile-detail/ProfileDetail';
import PatientPrescription from './pages/patient-prescription-list/PatientPrescription';
import PrescriptionDetail from './pages/patient-prescription-detail/PatientPrescriptionDetail';
import OtherPrescriptionDetail from './pages/patient-prescription-detail/OtherPrescriptionDetail';
import { ConnectedRouter } from 'connected-react-router';
import ContactUs from './pages/contact-us';
import Faqs from './pages/faqs';
import EditPatientProfile from './pages/profile-detail/EditProfile';
import PatientImmunization from './pages/patient-immunization/PatientImmunizations';
import PrescriptionPermission from './pages/patient-prescription-list/PrescriptionPermission';
import PatientCertificate from './pages/PatientCertificate';

function NoMatch({ location }) {
  return (
    <section className="middle-wrapper v-center">
      <div className="container">
        <div className="page-404">
          <i className="page-404-icon"></i>
          <h3>Page Not Found {location.pathname}</h3>
          <p>Sorry, we couldn't find the page you were looking for</p>
          <Link to="/dashboard" className="btn">Go to Home</Link>
        </div>
      </div>
    </section>
  );
}

function App({ history, checked }) {
  return (
    <ConnectedRouter history={history}>
      {checked &&
        <Switch>
          <PublicLayout exact from="/" exact component={LoginPage} />
          <PublicLayout path="/login" exact component={LoginPage} />
          <PublicLayout path="/registration" exact component={RegistrationPage} />
          <PublicLayout path="/verify" exact component={RegistrationOtp} />
          <PublicLayout path="/forgot-password" exact component={ForgotPasswordPage} />
          <PublicLayout path="/reset-password" exact component={ResetPasswordPage} />
          <PrivateLayout path="/edit" exact component={editProfile} />
          <PrivateLayout path="/dashboard" exact component={DashboardPage} />
          <PrivateLayout path="/patient-code" exact component={PatientCode} />
          <PrivateLayout path="/otp" exact component={PatientOtp} />
          <PrivateLayout path="/profile-detail/:patientId" exact component={ProfileDetail} />
          <PrivateLayout path="/patients/:patientId/prescriptions" exact component={PatientPrescription} />
          <PrivateLayout path="/patients/:patientId/prescriptions/:prescriptionId" exact component={PrescriptionDetail} />
          <PrivateLayout path="/patients/:patientId/other-prescriptions/:prescriptionId" exact component={OtherPrescriptionDetail} />
          <PrivateLayout path="/change-password" exact component={ChangePasswordPage} />
          <PublicLayout path="/contact-us" exact component={ContactUs} />
          <PublicLayout path="/faqs" exact component={Faqs} />
          <Route path="/mobile/contact-us" exact component={ContactUs} />
          <Route path="/mobile/faqs" exact component={Faqs} />
          <PrivateLayout path="/patients/:patientId/edit" exact component={EditPatientProfile} />
          <PrivateLayout path="/patients/:patientId/immunizations" exact component={PatientImmunization} />
          <PrivateLayout path="/patients/:patientId/prescription-permissions" exact component={PrescriptionPermission} />
          <PrivateLayout path="/patients/:patientId/certificates" exact component={PatientCertificate} />
          <Route component={NoMatch} />
        </Switch>}
    </ConnectedRouter>
  );
}

const mapStateToProps = state => ({
  checked: state.session.checked,
})

export default connect(mapStateToProps)(App);
