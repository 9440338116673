import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString, getAuthorizationToken } from '../utils/helpers';

class AuthService {
    static login(contact_number, password) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({ contact_number: contact_number, password: password });

        return axios.post(apiRoute('/v1/login'), body, requestOptions);

    }

    static registration(name, email, contact_number, password, password_confirmation) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({ name: name, email: email, contact_number: contact_number, password: password, password_confirmation: password_confirmation });

        return axios.post(apiRoute('/v1/register'), body, requestOptions);

    }
    static registrationVerify(otp, contact_number) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({ otp: otp, contact_number: contact_number });

        return axios.post(apiRoute('/v1/register/verify'), body, requestOptions);
    }
    static registrationOtpResend(contact_number) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({ contact_number: contact_number });

        return axios.post(apiRoute('/v1/register/resend'), body, requestOptions);
    }

    static logout() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/logout'), "", requestOptions);
    }

    static forgotPassword(contact_number) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({ contact_number: contact_number });

        return axios.post(apiRoute('/v1/forget-password'), body, requestOptions);
    }

    static resetPassword(contact_number, otp, password, password_confirmation, history) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({
            contact_number: contact_number,
            otp: otp,
            password: password,
            password_confirmation: password_confirmation
        });

        return axios.post(apiRoute('/v1/reset-password'), body, requestOptions);
    }

    static changePassword(stateData, token) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        const body = JSON.stringify({
            current_password: stateData.current_password,
            password: stateData.password,
            password_confirmation: stateData.password_confirmation
        });

        return axios.post(apiRoute('/v1/change-password'), body, requestOptions);
    }

    static editProfile(name, email) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        const body = JSON.stringify({
            full_name: name,
            email: email,
        });

        return axios.post(apiRoute('/v1/profile'), body, requestOptions);
    }

    static addPatientCode(patient) {
        const requestOptions = {
            headers: getApiHeader(null, true),
        };
        const body = JSON.stringify({
            patient_code: patient,
        });

        return axios.post(apiRoute('/v1/patients/attach/request'), body, requestOptions);
    }

    static patientOtp(otp, patient_code) {
        const requestOptions = {
            headers: getApiHeader(null, true),
        };
        const body = JSON.stringify({
            otp: otp,
            patient_code: patient_code
        });

        return axios.post(apiRoute('/v1/patients/attach/verify'), body, requestOptions);
    }

    static getPatient() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/patients'), requestOptions);
    }

    static getPatientById(patientId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/patients/' + patientId), requestOptions);
    }

    static getPrescriptionList(patientId, { page, sizePerPage, sortBy }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy })
        };

        return axios.get(apiRoute('/v1/patients/' + patientId + '/prescriptions'), requestOptions);
    }

    static getOtherPrescription(patientId, { page, sizePerPage, sortBy }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy })
        };

        return axios.get(apiRoute('/v1/patients/' + patientId + '/other-prescriptions'), requestOptions);
    }

    static getPathologyList(patientId, { page, sizePerPage, sortBy }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy })
        };

        return axios.get(apiRoute('/v1/patients/' + patientId + '/pathology-orders/documents'), requestOptions);
    }
    static getRadiologyList(patientId, { page, sizePerPage, sortBy }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy })
        };

        return axios.get(apiRoute('/v1/patients/' + patientId + '/radiology-orders/documents'), requestOptions);
    }
    static getPrescriptionDetail(patientId, prescriptionId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v2/patients/' + patientId + '/prescriptions/' + prescriptionId), requestOptions);
    }

    static permissionStatus(patientId, data) {
        const postData = JSON.stringify(data)
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute('/v1/patients/' + patientId + '/prescription-permissions'), postData, requestOptions);
    }

    static patientDetach(patientCode) {
        const postData = JSON.stringify({ patient_code: patientCode });
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute('/v1/patients/detach'), postData, requestOptions);
    }

    static getDocumentDownloadUrl(documentId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/documents/' + documentId + '/download'), requestOptions);
    }

    static updatePatientDetails(patientId, data) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        const postData = JSON.stringify(data);
        return axios.put(apiRoute('/v1/patients/' + patientId), postData, requestOptions);
    }
    static uploadProfilePicture(patientId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/patients/' + patientId + '/profile-picture'), data, requestOptions);
    }

    static getPatientsImmunization(patient_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/patients/" + patient_id + "/immunizations"), requestOptions);
    }

    static getPatientsCertificates(patient_id, { page, sizePerPage, sortBy }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy })
        };

        return axios.get(apiRoute("/v1/patients/" + patient_id + "/medical-certificates"), requestOptions);
    }

    static downloadCertificate(certificate_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/medical-certificates/" + certificate_id + "/download"), requestOptions);
    }

    static getPatientUserNotification() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/notifications"), requestOptions);
    }

    static deleteProfilePicture(patient_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.delete(apiRoute("/v1/patients/" + patient_id + "/profile-picture"), requestOptions);
    }

    static refreshToken() {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const token = getAuthorizationToken();

        const body = token ? JSON.stringify({
            refresh_token: token.refresh_token,
            access_token: token.access_token
        }) : "";

        return axios.post(apiRoute('/v1/refresh-token'), body, requestOptions);
    }

    static showOtherPrescription(patientId, prescriptionId) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute("/v1/patients/" + patientId + "/other-prescriptions/" + prescriptionId), requestOptions);
    }

    static downloadBodyChart(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/prescriptions/" + prescriptionId + "/body-chart"), requestOptions);

    }
}

export default AuthService;
